



































































import { FormValidations } from "@/mixins/form-validations";
import { Navigation } from "@/mixins/navigation";
import { Permissions } from "@/mixins/permissions";
import { CustomDate } from "@/mixins/custom-date";
import PageTitle from "@/components/General/PageTitle.vue";
import Component, { mixins } from "vue-class-component";
import { Watch } from "vue-property-decorator";
import Loader from "@/components/General/Loader.vue";
import Empty from "@/components/General/Empty.vue";
import { Notification } from "@/models/notification.interface";
import BillChat from "@/components/Input/BillChat.vue";
import { StyleCheck } from "@/mixins/style-check";
import BarSearch from "@/components/Input/BarSearch.vue";
@Component({
  components: { PageTitle, Loader, Empty, BillChat, BarSearch },
})
export default class CommentsOnBills extends mixins(
  FormValidations,
  Navigation,
  Permissions,
  CustomDate,
  StyleCheck
) {
  search = "";
  dialog = false;
  loader = false;
  loading = false;
  loadingDT = false;
  selectedSubscriptors = [];
  options = {};
  cancelBillID = null;
  loadingCB = false;
  cancelBillDialog = false;

  pagination = {
    itemsPerPage: parseInt(process.env.VUE_APP_DEFAULT_PAGINATION),
    totalItems: 0,
    page: 1,
  };

  @Watch("options", { deep: true })
  private async setItems(pagination) {
    this.loadingDT = true;
    this.pagination.itemsPerPage = pagination.itemsPerPage;

    await this.getBills(
      pagination.page,
      pagination.itemsPerPage == -1
        ? this.pagination.totalItems
        : this.pagination.itemsPerPage
    );
    this.loadingDT = false;
  }

  private async getBills(
    page: number,
    size: number,
    bill_id = ""
  ): Promise<void> {
    await this.$store
      .dispatch("bill/getBills", { page, size, bill_id, operation: "minus" })
      .catch(() => {
        const Error: Notification = {
          message: this.$tc("Views.s-e1"),
          timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
          top: true,
          bottom: false,
          left: false,
          right: false,
          currentPath: this.$route.fullPath,
          error: true,
        };

        this.$store.dispatch("notifications/showNotification", Error);
      });
  }

  private async created() {
    this.loader = true;
    await this.getBills(this.pagination.page, this.pagination.itemsPerPage);
    this.loader = false;
  }

  private get headers() {
    return [
      {
        text: this.$tc("Views.cob-6"),
        value: "client",
        align: "center",
        sortable: false,
      },
      {
        text: this.$tc("Views.cob-7"),
        value: "bill_id",
        align: "center",
        sortable: false,
      },
      {
        text: this.$tc("Products.headers.actions"),
        value: "actions",
        sortable: false,
        align: "center",
      },
    ];
  }

  private get userInfo(): any {
    let user = this.$store.getters["authentication/getUser"];
    if (user) {
      return user;
    } else {
      return "";
    }
  }

  private get bills(): any[] {
    let response = this.$store.getters["bill/getBills"];
    this.pagination.totalItems = response.totalItems;
    this.pagination.page = parseInt(response.currentPage);

    if (response?.bills) {
      return response?.bills;
    } else {
      return [];
    }
  }

  private async searchBillByID() {
    this.loadingDT = true;
    await this.getBills(
      this.pagination.page,
      this.pagination.itemsPerPage == -1
        ? this.pagination.totalItems
        : this.pagination.itemsPerPage,
      this.search
    );
    this.loadingDT = false;
  }
}
